import rosettaSdk from "@product/rosetta-sdk";
import { notEmpty, PageType } from "@product/scmp-sdk";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { useEffect, useRef } from "react";

import { sendGTMSubscribeVariables } from "~/components/tracking/google-tag-manager/apis";
import { config } from "~/data";
import { getAccountState } from "~/lib/account";
import { useRosettaConfig } from "~/lib/app/hooks";
import { useParsedCookie } from "~/lib/hooks";
import { setPianoCustomVariable } from "~/lib/rosetta/helpers";
import { useCurrentPageType, useRouteDetector } from "~/lib/router/hooks";
import { isExternalScriptsDisabled } from "~/lib/utils";

import { useRosettaEventHandlers } from "./rosetta-event-handlers";
import { useRosettaSetState } from "./rosetta-set-state";

export const useRosettaSetup = () => {
  const initialized = useRef(false);
  const { handleUpdateRosettaState } = useRosettaSetState();
  const { pianoAccessToken, user } = getAccountState();
  const { update: updateScmpSubscriberCookie } = useParsedCookie("scmp_subscriber", raw => raw, {
    domain: config.account.cookieDomain,
    expires: 1,
  });
  const currentPageType = useCurrentPageType();
  const { getUrlAliasByUrl } = useRouteDetector();

  const { attachEventHandlers } = useRosettaEventHandlers();

  const [_, { execute }] = useAsync(async () => {
    if (initialized.current || isExternalScriptsDisabled()) return;
    initialized.current = true;

    rosettaSdk
      .setConfig({
        contentService: {
          apiKey: config.rosetta.contentService.apiKey,
          apiUri: `${config.rosetta.apiGatewayHost}/content-delivery/v1`,
          env: parseContentServiceEnvironment(config.rosetta.contentService.environment),
        },
        piano: {
          aid: config.rosetta.piano.applicationId,
          apiUri: config.rosetta.piano.apiUri,
          env: parsePianoEnvironment(config.rosetta.piano.environment),
        },
      })
      .setPageUrl(`${location.origin}${location.pathname}`)
      .setToken(pianoAccessToken);

    rosettaSdk.registerService("geo", {
      apiKey: config.rosetta.contentService.apiKey,
      apiUri: `${config.rosetta.apiGatewayHost}/gloc/v1`,
    });

    rosettaSdk.registerService("tierInfo", {
      apiKey: config.rosetta.contentService.apiKey,
      apiUri: `${config.corporateSubscriber.host}/tier`,
    });

    rosettaSdk.registerService("pianoUserInfo", {
      aid: config.rosetta.piano.applicationId,
      apiUri: `${config.rosetta.subscribeHost}/api/user`,
    });

    rosettaSdk.registerService("pianoPublisherConversions", {
      aid: config.rosetta.piano.applicationId,
      apiUri: `${config.rosetta.subscribeHost}/api/user/conversions`,
    });

    rosettaSdk.registerService("onboarding", {
      env: parseContentServiceEnvironment(config.rosetta.contentService.environment),
      urlPrefix: config.rosetta.subscribeTemplateHost,
    });

    rosettaSdk.registerService("cartAbandoner", {
      cookieDomain: config.account.cookieDomain,
      env: parseContentServiceEnvironment(config.rosetta.contentService.environment),
      urlPrefix: config.rosetta.subscribeTemplateHost,
      userTypeApiUri: `${config.general.userTypeHost}/client-id/`,
    });

    const handleGTMSubscribeVariables = (
      user?: rosettaSdk.User,
      tracking?: rosettaSdk.Tracking,
    ) => {
      const { clientName, subscriptionProducts, tier } = user ?? {};
      const { userRole } = tracking ?? {};
      notEmpty(clientName) && sendGTMSubscribeVariables({ ipClientName: clientName });
      notEmpty(tier) && sendGTMSubscribeVariables({ tier });
      notEmpty(subscriptionProducts?.[0]) &&
        sendGTMSubscribeVariables({ product: subscriptionProducts?.[0] });
      notEmpty(userRole) && sendGTMSubscribeVariables({ userRole });
    };

    const handlePianoCustomVariables = (tracking?: rosettaSdk.Tracking) => {
      setPianoCustomVariable("SUBSCRIBER_STATUS", tracking?.subscriberStatus);

      const username =
        user?.username?.split?.("@")?.[0] ?? `${user?.firstname ?? ""} ${user?.lastname ?? ""}`;
      notEmpty(username?.trim()) && setPianoCustomVariable("USER_NAME", username);

      const getSubscribeUrl = () => {
        const subscribeUrl = new URL(config.rosetta.subscribeHost);
        subscribeUrl.searchParams.set("destination", location.origin);
        return subscribeUrl.toString();
      };
      setPianoCustomVariable("subscribeUrl", getSubscribeUrl());
    };

    try {
      await rosettaSdk.init();
      attachEventHandlers();
      await rosettaSdk.initRules();
      const { supportPiano: isSubscriptionEnabled, supportVelocityWall: isShowVelocityWall } =
        rosettaSdk.rulesEngine.getOutcome("guest");

      // Article page will be executed after variable has been set with apps/app/components/article/article-render/hooks/piano-integration/index.ts
      if (![PageType.Article, PageType.PostiesArticle].includes(currentPageType)) {
        rosettaSdk.execute();
      }

      const hasToken = rosettaSdk.rulesEngine.getFact("hasToken");
      const user = rosettaSdk.rulesEngine.getOutcome("user");
      const tracking = rosettaSdk.rulesEngine.getOutcome("tracking");
      const isScmpSubscriber = rosettaSdk.rulesEngine.getOutcome("user.isSCMPSubscriber");
      const isPostiesSubscriber = rosettaSdk?.rulesEngine.getOutcome("user.isPostiesSubscriber");
      const hasPostiesAccessRight = rosettaSdk?.rulesEngine.getOutcome(
        "user.hasPostiesAccessRight",
      );
      handleGTMSubscribeVariables(user, tracking);
      handlePianoCustomVariables(tracking);
      updateScmpSubscriberCookie(tracking.subscriberStatus);
      handleUpdateRosettaState(
        {
          contentServiceUserInfo: {
            isShowVelocityWall,
            isSubscriptionEnabled,
          },
          hasPostiesAccessRight,
          instance: rosettaSdk,
          isPostiesSubscriber,
          isScmpSubscriber,
          tracking,
          user,
        },
        "success",
      );

      // cart abandoner trigger
      const currentUrlAlias = getUrlAliasByUrl(window.location.href);
      const canShowCartAbandoner = ["/scmp_comments", "/posties", "/newsletters", "/yp"].every(
        x => !currentUrlAlias.startsWith(x),
      );
      if (canShowCartAbandoner) {
        rosettaSdk.getService("cartAbandoner")?.placeTrigger({
          hasToken,
          isRosetta: false,
          isSCMPSubscriber: isScmpSubscriber,
        });
      }
    } catch (error) {
      console.error("rosettaSdk init error", error);
      handleUpdateRosettaState(
        undefined,
        "error",
        error instanceof Error ? error : new Error(JSON.stringify(error)),
      );
    }
    try {
      rosettaSdk.initiateSwGLinkingFlow();
    } catch (error) {
      console.error("rosettaSdk initiateSwGLinkingFlow", error);
    }
  });
  useMountEffect(execute);

  const { rosettaConfigState } = useRosettaConfig();
  useEffect(() => {
    const {
      isShowPremiumPromo = false,
      oldArticleTimeframe = 15,
      paywallCampaign,
    } = rosettaConfigState.result?.paywallSettings ?? {};
    const { storage } = rosettaConfigState.result?.mixpanelSettings ?? {};
    handleUpdateRosettaState({
      appSettings: {
        isShowPremiumPromo,
        mixpanelStorage: storage,
        oldArticleTimeframe,
        paywallCampaignName: paywallCampaign?.name,
      },
      posties: {
        disablePaywall: rosettaConfigState.result?.posties?.disablePaywall,
      },
    });
  }, [handleUpdateRosettaState, rosettaConfigState]);

  function parseContentServiceEnvironment(s: string) {
    switch (s.toLocaleLowerCase()) {
      case "production":
        return "production";
      default:
        return "development";
    }
  }

  function parsePianoEnvironment(s: string) {
    switch (s.toLocaleLowerCase()) {
      case "production":
        return "production";
      default:
        return "sandbox";
    }
  }
};

declare global {
  interface Window {
    tp?: Piano;
  }

  interface Piano extends Array<unknown> {
    customVariables?: {
      CHURN_EXPIRE_DATE?: number;
      CHURN_TERM_NAME?: string;
      SUBSCRIBER_STATUS?: string;
      USER_NAME?: string;
      currency?: string;
      geoLocation?: string;
      ip_access_client?: string;
      ip_access_user?: boolean;
      ip_ezproxy_user?: boolean;
      paywallCampaign?: string;
      subscribeLink?: string;
      trackingId?: string;
      zone?: string;
    };
    experience?: {
      execute: () => void;
    };
    offer?: {
      close: () => void;
    };
    user?: {
      isUserValid: () => boolean;
    };
  }
}

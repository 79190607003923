import type { CheckoutCustomPayload } from "@product/rosetta-sdk";
import { safeSetLocalStorage } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import { useCallback } from "react";

import { useLoginDialogStateHelper } from "~/components/login-dialog/hooks";
import { registerMixPanelParameters, trackMixPanel, useMixpanel } from "~/components/mixpanel";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import { sendGTMSubscribeVariables } from "~/components/tracking/google-tag-manager/apis";
import { getGoogleLoginUrl } from "~/lib/account";
import { rosettaAtom } from "~/lib/rosetta";

import {
  BeyondMaxReadableArticleCountPerLoggedInUser,
  RegiWallLoginMethodLocalstorageKey,
} from "./consts";

export const useRosettaCheckoutCustomEventHandler = () => {
  const { openLoginDialog } = useLoginDialogStateHelper();
  const mixpanel = useMixpanel();

  const asyncRosettaState = useAtomValue(rosettaAtom);

  const handleCustomEvent = useCallback(
    (payload: CheckoutCustomPayload) => {
      const handleCheckoutCustomEventTracking = () => {
        const currentUrlPath = window.location.pathname;
        const {
          eventName,
          params: { collapse, meter },
        } = payload;

        if (!eventName || !meter) return;
        const BeyondMaxArticleCountString = BeyondMaxReadableArticleCountPerLoggedInUser.toString();
        const meterStateName =
          meter !== BeyondMaxArticleCountString && collapse === true ? "Minimize" : "Enlarged";

        switch (eventName) {
          case "meter-subscribe":
            sendGTMSubscribeVariables({ meter });
            sendGATracking({
              action: `Subscription Metering/${meterStateName}/Click Subscribe` as const,
              category: "Subscription Metering",
              label: currentUrlPath,
              value: meter,
            });
            registerMixPanelParameters(mixpanel.result, {
              "Metering Article Count": meter,
            });
            trackMixPanel(mixpanel.result, {
              name: "Subscription Metering",
              property: {
                "Metering Article Count": meter,
                "User Action": "Click Subscribe",
              },
            });
            break;
          case "meter-close-modal":
            sendGATracking({
              action: `Subscription Metering/${meterStateName}/Close` as const,
              category: "Subscription Metering",
              label: currentUrlPath,
              value: meter,
            });
            trackMixPanel(mixpanel.result, {
              name: "Subscription Metering",
              property: {
                "Metering Article Count": meter,
                "User Action": "Close",
              },
            });
            break;
          case "meter-signin":
            sendGATracking({
              action: `Subscription Metering/${meterStateName}/Sign in` as const,
              category: "Subscription Metering",
              label: currentUrlPath,
              value: meter,
            });
            trackMixPanel(mixpanel.result, {
              name: "Subscription Metering",
              property: {
                "Metering Article Count": meter,
                "User Action": "Sign in",
              },
            });
            break;
          case "meter-minimize":
            sendGATracking({
              action: `Subscription Metering/${meterStateName}/Impression` as const,
              category: "Subscription Metering",
              label: currentUrlPath,
              value: meter,
            });
            break;
          case "loginwall-meter-signin":
            // login wall meter, always Enlarged
            sendGATracking({
              action: "Subscription Metering/Login Wall/Enlarged/Sign in",
              category: "Subscription Metering",
              label: currentUrlPath,
              value: meter,
            });
            trackMixPanel(mixpanel.result, {
              name: "Subscription Metering",
              property: { "Metering Article Count": meter, "User Action": "Sign in" },
            });
            break;
          case "loginwall-meter-subscribe":
            // login wall meter, always Enlarged
            sendGATracking({
              action: "Subscription Metering/Login Wall/Enlarged/Click Subscribe",
              category: "Subscription Metering",
              label: currentUrlPath,
              value: meter,
            });
            registerMixPanelParameters(mixpanel.result, { "Metering Article Count": meter });
            trackMixPanel(mixpanel.result, {
              name: "Subscription Metering",
              property: { "Metering Article Count": meter, "User Action": "Click Subscribe" },
            });
            break;
          case "sep-article-footer-close":
            sendGATracking({
              action: "Subscription Entry Point/Article Footer/Close",
              category: "Subscription Entry Point",
              label: currentUrlPath,
            });
            trackMixPanel(mixpanel.result, {
              name: "Subscription Entry Point/Article Footer",
              property: { "User Action": "Close" },
            });
            break;
          case "onboarding-direct-checkout": {
            const {
              params: { action: eventAction, plan: period },
            } = payload;

            if (period) {
              const {
                params: { currency, price },
              } = payload;
              const plan = `${period === "year" ? "Annual" : "Monthly"}: Digital | ${currency}`;
              sendGTMSubscribeVariables({ meter, period, plan, price });
            }
            if (!eventAction) return;
            sendGATracking({
              action: eventAction,
              category: "Onboarding/Pop-up",
              label: window.location.href,
            });
            break;
          }
          case "sep-article-footer-support":
            sendGATracking({
              action: "Subscription Entry Point/Article Footer/Support Us",
              category: "Subscription Entry Point",
              label: currentUrlPath,
            });
            trackMixPanel(mixpanel.result, {
              name: "Subscription Entry Point/Article Footer",
              property: { "User Action": "Support Us" },
            });
            break;
          case "meter-direct-checkout": {
            const {
              params: { action: eventAction, plan: period },
            } = payload;

            if (period) {
              const {
                params: { currency, price },
              } = payload;
              const plan = `${period === "year" ? "Annual" : "Monthly"}: Digital | ${currency}`;
              sendGTMSubscribeVariables({ meter, period, plan, price });
            }
            if (!eventAction) return;

            const isWinback =
              !!asyncRosettaState?.result?.instance?.rulesEngine.getOutcome("user")
                ?.scmpWinbackPeriod;
            sendGATracking({
              action: eventAction,
              category: "Subscribe to SCMP",
              label: window.location.href,
              value: isWinback ? "Winback" : currentUrlPath,
            });
            registerMixPanelParameters(mixpanel.result, {
              "Metering Article Count": meter,
            });
            trackMixPanel(mixpanel.result, {
              name: "Subscription Metering",
              property: {
                "Metering Article Count": meter,
                "User Action": "Click Subscribe",
              },
            });
            break;
          }
        }
      };

      handleCheckoutCustomEventTracking();

      switch (payload.eventName) {
        case "regiwall-google-signin":
          safeSetLocalStorage(RegiWallLoginMethodLocalstorageKey, "Google");
          location.href = getGoogleLoginUrl({
            registerSource: "loginwall",
          });
          break;
        case "loginwall-meter-signin":
        case "loginwall-meter-signup":
        case "regiwall-signin":
          openLoginDialog({
            description: "To continue reading, create your free account or log in now",
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              trigger_point: "paywall",
            },
            wallType: "regi",
          });
          break;
        case "regiwall-elections-meter-signup":
          openLoginDialog({
            description: "To continue reading, create your free account or log in now",
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              trigger_point: "paywall",
            },
            registrationTerm: "hkelections",
            wallType: "regi",
          });
          break;
        case "regiwall-elections-meter-redeem":
          const updatedUrl = new URL(window.location.href);
          // cspell: ignore hkelections
          updatedUrl.searchParams.set("hkelections", "true");
          window.location.href = updatedUrl.href;
          break;
        case "onboarding-direct-checkout":
          if (!payload?.params) return;
          const { params } = payload;
          if (params.plan) {
            sendGTMSubscribeVariables({
              meter: params.meter,
              period: params.plan,
              plan: `${params.plan === "year" ? "Annual" : "Monthly"}: Digital | ${
                params.currency
              }`,
              price: params.price,
            });
          }
          sendGATracking({
            action: params.action,
            category: "Onboarding/Pop-up",
            label: window.location.href,
          });
          break;
      }
    },
    [asyncRosettaState?.result?.instance?.rulesEngine, mixpanel.result, openLoginDialog],
  );

  return {
    handleCustomEvent,
  };
};

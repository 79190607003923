// cspell:ignore Winback
import type { Fact, MeterData } from "@product/rosetta-sdk";
import rosettaSdk from "@product/rosetta-sdk";
import { notEmpty, safeSetLocalStorage } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import { useCallback } from "react";

import { useLoginDialogStateHelper } from "~/components/login-dialog/hooks";
import { registerMixPanelParameters, trackMixPanel, useMixpanel } from "~/components/mixpanel";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import { sendGA4Tracking } from "~/components/tracking/google-analytics-4/apis";
import {
  sendGTMSubscribeVariables,
  sendGTMTracking,
} from "~/components/tracking/google-tag-manager/apis";
import type { UntypedGA4Event } from "~/components/tracking/google-tag-manager/types";
import { accountAtom } from "~/lib/account/atoms";
import { PaywallMeterModalClassName } from "~/lib/rosetta/consts";
import { eventEmitter } from "~/lib/rosetta/event-emitter";
import {
  MaxReadableArticleCountPerLoggedInUser,
  PaywallMeterViewsLeftLocalstorageKey,
  PaywallMeterViewsLocalstorageKey,
} from "~/lib/rosetta/hooks/rosetta-event-handlers/consts";
import { useRosettaSetState } from "~/lib/rosetta/hooks/rosetta-set-state";

import { useRosettaCheckoutCustomEventHandler } from "./checkout-custom-event-handler";

export const useRosettaEventHandlers = () => {
  const { openLoginDialog } = useLoginDialogStateHelper();
  const { handleUpdateRosettaState } = useRosettaSetState();
  const { handleCustomEvent } = useRosettaCheckoutCustomEventHandler();
  const { isLoggedIn } = useAtomValue(accountAtom);
  const mixpanel = useMixpanel();

  const attachEventHandlers = useCallback(() => {
    // TODO: add the event handlers after https://scmp-product-tech.atlassian.net/browse/SCMPWEB-2651 clean up the code

    const handleUpdateRosettaMeterData = (meterData: MeterData) => {
      const { maxViews, views, viewsLeft } = meterData;
      // cspell: ignore regi
      // ignore max = 1 (regi wall) and max = 50 (ad block)
      if ([1, 50].includes(maxViews)) return;
      handleUpdateRosettaState({ meterData });
      safeSetLocalStorage(PaywallMeterViewsLocalstorageKey, `${views}`);
      safeSetLocalStorage(PaywallMeterViewsLeftLocalstorageKey, `${viewsLeft}`);
    };

    rosettaSdk.on("checkoutCustomEvent", payload => {
      if (!payload) return;
      handleCustomEvent(payload);
    });

    rosettaSdk.on("experienceExecute", async context => {
      const handleIPWhitelistGTMVariables = () => {
        const isIPWhiteListCases =
          window.tp?.customVariables?.ip_access_user &&
          window.tp?.customVariables?.ip_access_client;
        if (!isIPWhiteListCases) return;

        sendGTMSubscribeVariables({
          ipClientName: window.tp?.customVariables?.ip_access_client,
          product: "SCMP - IP whitelisting",
        });

        if (window.tp?.customVariables?.ip_ezproxy_user)
          sendGTMSubscribeVariables({ ipClientIsEZproxy: "EZproxy" });
        else sendGTMSubscribeVariables({ ipClientIsEZproxy: "IP whitelisting" });
      };

      handleIPWhitelistGTMVariables();
      const showInlineEvent = context.result?.events?.find?.(
        event => event?.eventParams?.displayMode === "inline",
      );
      const hasIpAccess = computeHasIpAccess();
      rosettaSdk.rulesEngine.addFact("hasIpAccess", computeHasIpAccess());
      rosettaSdk.rulesEngine.setReady("pianoExperience");
      await rosettaSdk.rulesEngine.run();
      handleUpdateRosettaState({ hasIpAccess });
      eventEmitter.emit("showInlineWall", notEmpty(showInlineEvent));

      // The window.tp variable is populated after running rosettaSdk.execute
      function computeHasIpAccess() {
        return (
          (typeof window !== "undefined" && window?.tp?.customVariables?.ip_access_user) ?? false
        );
      }
    });

    rosettaSdk.on("showOffer", offerParameters => {
      const handleModalOffer = () => {
        if (offerParameters.displayMode !== "modal") return;
        document.body.classList.add(PaywallMeterModalClassName);
      };

      // TODO: handle `closePianoMeter()` if displayMode === modal, code:elBody.classList.add('offer-meter-open')
      const handleHardPaywallTrackingAndUpdateData = () => {
        if (!offerParameters.activeMeters) return;
        const activeMeters = JSON.parse(offerParameters.activeMeters) as MeterData[];
        if (!activeMeters || activeMeters.length === 0) return;

        const [meterData] = activeMeters; // Assume it has one meter
        const { maxViews, totalViews, viewsLeft } = meterData;
        const isHardPaywall =
          totalViews > MaxReadableArticleCountPerLoggedInUser &&
          maxViews === MaxReadableArticleCountPerLoggedInUser &&
          viewsLeft === 0;

        if (!isHardPaywall) return;

        const hardPaywallViewCountForTracking = (
          MaxReadableArticleCountPerLoggedInUser + 1
        ).toString();

        const getGTMTrackingLabel = () => {
          const isWinback = rosettaSdk.rulesEngine.getOutcome("user.scmpWinbackPeriod") === "true";
          const paywallCampaign = window.tp?.customVariables?.paywallCampaign;
          return isWinback ? "Winback" : paywallCampaign ?? window.location.pathname;
        };

        // TODO: Refactor the code to get the abTestVariants from rosettaSdk
        const getPianoABTestVariant = () =>
          Object.entries(window?.tp?.customVariables ?? {}).reduce((variants, [key, value]) => {
            if (!key.startsWith("abTestVariants.")) return variants;
            const trimmedKey = key.replace("abTestVariants.", "");
            return variants ? `${variants}|${trimmedKey}:${value}` : `${trimmedKey}:${value}`;
          }, "");

        const abTestVariants = getPianoABTestVariant();
        if (abTestVariants) {
          sendGTMSubscribeVariables({ experiment: abTestVariants });
        }

        sendGATracking({
          action: `Subscribe to SCMP/${hardPaywallViewCountForTracking}th Article Mask/Impression`,
          category: "Subscribe to SCMP",
          label: getGTMTrackingLabel(),
          value: hardPaywallViewCountForTracking,
        });
        registerMixPanelParameters(mixpanel.result, {
          "Metering Article Count": MaxReadableArticleCountPerLoggedInUser + 1,
        });
        trackMixPanel(
          mixpanel.result,
          {
            name: "Subscription Metering",
            property: { "User Action": "Impression" },
          },
          { untyped: true },
        );

        const mixpanelId = mixpanel.result?.get_distinct_id() as string | undefined;
        if (mixpanelId) {
          sendGTMSubscribeVariables({ mixpanelId });
        }
        handleUpdateRosettaMeterData(meterData);

        if (isLoggedIn) {
          rosettaSdk.getService("cartAbandoner")?.showCartAbandonerOfferOnNextVisit();
        }
      };

      handleModalOffer();
      handleHardPaywallTrackingAndUpdateData();
    });

    rosettaSdk.on("meterActive", meterData => {
      handleUpdateRosettaMeterData(meterData);
    });

    rosettaSdk.on("meterExpired", meterData => {
      const { maxViews, totalViews, type } = meterData;
      if (type === "pageViews" && maxViews > 0 && totalViews === maxViews + 1) {
        sendGTMTracking({ event: "meter-expired" });
      }
    });

    rosettaSdk.registerListener("pwa/request-from-template");
    rosettaSdk.on("COMM_CHANNEL", parameters => {
      // TODO: refactor those events which is calling rosettaSDK directly, no point to round trip
      const { message, source = "" } = parameters;
      if (!message?.requestType) return;
      switch (message.requestType) {
        // TODO: seems those state/* not in use, so will skip it for now, will skip optimize related code as well
        case "context/subscriptionStatus":
          message.responseData = rosettaSdk.rulesEngine.getOutcome("user");
          break;
        case "context/appConfig":
        case "appConfig/rosetta":
          message.responseData = rosettaSdk.getRosettaAppConfig();
          break;
        case "context/subscriberPhase":
          message.responseData = rosettaSdk.rulesEngine.getOutcome("tracking.ga4.subscriberPhase");
          break;
        case "action/showPaywall":
          eventEmitter.emit("showInlineWall", true);
          message.responseData = "ready";
          break;
        case "tp/customVariables":
          message.responseData = window.tp?.customVariables ?? {};
          break;
        case "action/trackGA4":
          // Skip the type checking at here
          sendGA4Tracking(message.requestData as unknown as UntypedGA4Event, { untyped: true });
          break;
        case "monitor/wallImpression":
          rosettaSdk.subscribeWaiter(message.requestType, "pwa/response-from-app", source, true);
          break;
        case "rule/facts":
          message.responseData =
            message.requestData.name === "*"
              ? rosettaSdk.rulesEngine.getAllFacts()
              : rosettaSdk.rulesEngine.getFact(message.requestData.name as keyof Fact);
          break;
        case "rule/outcome":
          message.responseData =
            message.requestData.name === "*"
              ? rosettaSdk.rulesEngine.getAllOutcome()
              : rosettaSdk.rulesEngine.getOutcome(message.requestData.name);
          break;
        case "login/paywall":
          openLoginDialog({
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              trigger_point: "paywall",
            },
            wallType: "paywall",
          });
          break;
        case "login/velocity":
          openLoginDialog({
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              trigger_point: "paywall",
            },
            wallType: "velocity",
          });
          break;
        case "login/archive":
          openLoginDialog({
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              trigger_point: "paywall",
            },
            wallType: "archive",
          });
          break;
        case "login/meter2":
          openLoginDialog({
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              trigger_point: "paywall",
            },
            wallType: "meter2",
          });
          break;
        case "login/meter4":
          openLoginDialog({
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              trigger_point: "paywall",
            },
            wallType: "meter4",
          });
          break;
        case "login/newsletter":
          openLoginDialog({
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              trigger_point: "paywall",
            },
            wallType: "newsletter",
          });
          break;
        case "localStorage/get":
          const localStorageGetVariable = message.requestData?.localStorage;
          if (localStorageGetVariable?.includes("rSDK_")) {
            const localStorageResult = localStorage.getItem(localStorageGetVariable);
            message.responseData = {
              localStorageResult,
              localStorageVar: localStorageGetVariable,
            };
          }
          break;
        case "localStorage/set":
          const localStorageSetVariable = message.requestData?.localStorage;
          const localStorageValue = message.requestData?.localStorageVal;
          if (localStorageSetVariable?.includes("rSDK_") && localStorageValue) {
            safeSetLocalStorage(localStorageSetVariable, JSON.stringify(localStorageValue));
            message.responseData = {
              localStorageResult: localStorageValue,
              localStorageVar: localStorageSetVariable,
            };
          }
          break;
        case "cartAbandoner": {
          const svc = rosettaSdk.getService("cartAbandoner");
          switch (message.requestData?.action) {
            case "login":
              openLoginDialog({
                destination: window.location.origin + window.location.pathname,
                ga4CustomParameter: {
                  trigger_point: "abandoned cart popup",
                },
              });
              break;
            case "registerOfferShown":
              svc?.registerOfferShown();
              break;
            case "cartAbandonerOfferShown":
              svc?.cartAbandonerOfferShown();
              break;
            case "clearCookies":
              svc?.cleanupCookies();
              break;
          }
          return;
        }
        default:
          message.error = true;
          message.errorMessage = "unknown requestType";
          break;
      }
      const { channel: _channel, ...messageWithoutChannel } = message;
      rosettaSdk.sendMessage("pwa/response-from-app", {
        ...parameters,
        message: messageWithoutChannel,
      });
    });
  }, [handleCustomEvent, handleUpdateRosettaState, mixpanel.result, openLoginDialog, isLoggedIn]);

  return { attachEventHandlers };
};
